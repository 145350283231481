import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/dashBoardA'
  // },
  {
    path: '/',
    name: '仪表盘',
    meta: {
      requireAuth:true,
      title: "逾期订单"
    },
    // component: () => import('_views/AdminView'),
    component: () => import('_views/AdminView'),
    children: [
      {
        path: '/dashBoardA',
        name: '定位地址',
        component: () => import('_views/DashBoard/DashBoardA'),
        meta: {
					requireAuth:true,
					title: "首定位地址页"
				}
      },
      {
        path: '/dashBoardB',
        name: '收货地址',
        component: () => import('_views/DashBoard/DashBoardB'),
        meta: {
					requireAuth:true,
					title: "收货地址"
				}
      },
      {
        path: '/dashBoardC',
        name: '订单数据',
        component: () => import('_views/DashBoard/DashBoardC'),
        meta: {
					requireAuth:true,
					title: "订单数据"
				}
      },
      {
        path: '/dashBoardD',
        name: '逾期订单 ',
        component: () => import('_views/DashBoard/DashBoardD'),
        meta: {
					requireAuth:true,
					title: "逾期订单"
				}
      }
    ]
  },
  {
    path: '/',
    name: '常规订单',
    component: () => import('_views/AdminView'),
    children: [
      {
        path: '/orderViewA',
        name: '所有订单',
        component: () => import('_views/OrderView/OrderViewA'),
        meta: {
					requireAuth:true,
					title: "逾所有订单期订单"
				}
      },
      {
        path: '/orderViewB',
        name: '还租订单',
        component: () => import('_views/OrderView/OrderViewB'),
        meta: {
					requireAuth:true,
					title: "还租订单"
				}
      },
      {
        path: '/orderViewC',
        name: '逾期订单',
        component: () => import('_views/OrderView/OrderViewC'),
        meta: {
					requireAuth:true,
					title: "逾期订单"
				}
      },
      {
        path: '/OrderViewD/:id',
        name: '机审报告',
        component: () => import('_views/OrderView/OrderViewD'),
        meta: {
					requireAuth:true,
					title: "机审报告"
				}
      }
    ]
  },
  {
    path: '/',
    name: '黑名单库',
    component: () => import('_views/AdminView'),
    children: [
      {
        path: '/blackView',
        name: '逾期名单',
        component: () => import('_views/BlackView'),
        meta: {
					requireAuth:true,
					title: "逾期名单"
				}
      }
    ]
  },
  {
    path: '/',
    name: '平台管理',
    component: () => import('_views/AdminView'),
    children: [
      {
        path: '/orderManage',
        name: '平台列表',
        component: () => import('_views/OrderManage'),
        meta: {
					requireAuth:true,
					title: "平台列表"
				}
      },
    ]
  },
  {
    path: '/',
    name: '风控设置',
    component: () => import('_views/AdminView'),
    children: [
      {
        path: '/fengKongSettingA',
        name: '客户评级',
        component: () => import('_views/FengKongSetting/FengKongSettingA'),
        meta: {
					requireAuth:true,
					title: "客户评级"
				}
      },
      {
        path: '/fengKongSettingB',
        name: '接口管理',
        component: () => import('_views/FengKongSetting/FengKongSettingB'),
        meta: {
					requireAuth:true,
					title: "接口管理"
				}
      },
      {
        path: '/fengKongSettingC',
        name: '配置管理',
        component: () => import('_views/FengKongSetting/FengKongSettingC'),
        meta: {
					requireAuth:true,
					title: "配置管理"
				}
      }
    ]
  },
  {
    path: '/',
    name: '财务管理',
    component: () => import('_views/AdminView'),
    children: [
      {
        path: '/moneyManageA',
        name: '充值记录',
        component: () => import('_views/MoneyManage/MoneyManageA'),
        meta: {
					requireAuth:true,
					title: "充值记录"
				}
      },
      {
        path: '/moneyManageB',
        name: '消费记录',
        component: () => import('@/views/MoneyManage/MoneyManageB/index.vue'),
        meta: {
					requireAuth:true,
					title: "消费记录"
				}
      },
      {
        path: '/moneyManageC',
        name: '三方余额',
        component: () => import('_views/MoneyManage/MoneyManageC'),
        meta: {
					requireAuth:true,
					title: "三方余额"
				}
      }
    ]
  },
  {
    path: '/',
    name: '系统设置',
    component: () => import('_views/AdminView'),
    children: [
      {
        path: '/systemSetupA',
        name: '角色管理',
        component: () => import('_views/SystemSetup/SystemSetupA'),
        meta: {
					requireAuth:true,
					title: "角色管理"
				}
      },
      {
        path: '/systemSetupB',
        name: '用户管理',
        component: () => import('_views/SystemSetup/SystemSetupB'),
        meta: {
					requireAuth:true,
					title: "用户管理"
				}
      },
      {
        path: '/systemSetupC',
        name: '客户评级 ',
        component: () => import('_views/SystemSetup/SystemSetupC'),
        meta: {
					requireAuth:true,
					title: "客户评级"
				}
      },
      {
        path: '/systemSetupD',
        name: '字典管理',
        component: () => import('_views/SystemSetup/SystemSetupD'),
        meta: {
					requireAuth:true,
					title: "字典管理"
				}
      },
      {
        path: '/systemSetupE',
        name: '菜单管理',
        component: () => import('_views/SystemSetup/SystemSetupE'),
        meta: {
					requireAuth:true,
					title: "菜单管理"
				}
      },
      {
        path: '/paymentconfigure',
        name: '支付设置',
        component: () => import('_views/SystemSetup/paymentconfigure'),
        meta: {
					requireAuth:true,
					title: "支付设置"
				}
      }
    ]
  },
  {
    path: '/',
    name: '运营管理',
    component: () => import('_views/operateManage'),
    children: [
      {
        path: '/channelData',
        name: '渠道数据',
        component: () => import('_views/ChannelData/index.vue'),
        meta: {
          requireAuth:true,
          title: "渠道数据"
        }
      },
    ]
  },
  {
    path: '/login',
    name: '登录页',
    component: () => import('_views/Login/index.vue'),
    meta: {
      requireAuth:false,
      title: "菜单管理"
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
